<template>
  <div class="home">
    <top></top>
    <div class="centere">
      <div class="nav-wrap">
        <div class="count">
          <div class="item">
            <img src="../../assets/hope/phone.png" alt="" />
            <h2>电话客服</h2>
            <div class="first"><p>电话咨询客服</p><p>工作日8:30-17:30</p></div>
            <p class="two">4006008891</p>
          </div>
          <div class="item">
            <img src="../../assets/hope/mess.png" alt="" />
            <h2>在线客服</h2>
            <p class="first">客服在线解答您的问题</p>
            <p class="two cupro" @click="keyconsult">点击咨询</p>
          </div>
              <Commoditymessage  @clears="clears"     :WannaTalkdialog="WannaTalkdialog"
                :CompanyUserList="CompanyUserList"
                   @surees="surees" 
                ></Commoditymessage>
          <div class="item">
            <img src="../../assets/hope/message.png" alt="" />
            <h2>提建议</h2>
            <p class="first">欢迎提出您的宝贵建议</p>
            <p class="two cupro" @click="keyword">我要留言</p>
          </div>
        </div>
        <Leaveword
          :dialogTableVisible="dialogTableVisible"
          @clear="clear"
          @sure="sure"
        ></Leaveword>
        <div class="probleme">
          <h2>常见问题</h2>
          <div class="problemcenter">
            <div class="left">
              <el-menu
                default-active="0"
                class="el-menu-vertical-demo"
                active-text-color="#409eff"
                unique-opened
              >
                <el-submenu
                  :index="index + ''"
                  v-for="(item, index) in data"
                  :key="index"
                >
                  <!-- v-on:mouseover="myoneFunction(item)" -->
                  <template slot="title">
                    <!-- <i class="el-icon-user"></i> -->
                    <span> {{ item.name }} </span>
                  </template>
                  <el-menu-item
                    :index="index + ''"
                    v-for="(item1, index) in item.children"
                    :key="index"
                    @click="twosaveNave(item1.uuid)"
                  >
                    <template slot="title">
                      <!-- <i class="el-icon-user"></i> -->
                      <span> {{ item1.name }} </span>
                    </template>
                  </el-menu-item>
                </el-submenu>
              </el-menu>
            </div>
            <div class="right"><div id="test1"></div></div>
          </div>
        </div>
      </div>
    </div>
    <Foo></Foo>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import hopeApi from "../../api/hopeApi";
import Leaveword from "./leaveword.vue";
import Commoditymessage from "../../components/chat/customer.vue";
import userApi from "../../api/updataApi"
import imApi from "../../api/imApi";
import { Message } from "element-ui";
export default {
  data() {
    return {
      WannaTalkdialog:false,
      dialogTableVisible: false,
      tabPosition: "left",
      sd: "dasd",
      fefL: "ruiorj",
      data: [],
      pid: "",
      id: "",
      helperText: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      // 在线客服类表信息
        CompanyUserList: [],
        // 在线客服id列表
         offline:[
           "4c93fcf841354368a460a41283b9f106",
           "7c844713944143aa8ef9ac01e104b374",
         ],
    };
  },
  created() {
    this.getHelperGroup();
  },
  components: {
    // HelloWorld
    Top,
    Foo,
    Leaveword,
    Commoditymessage
  },
  methods: {
          clears(bool) {
      this.WannaTalkdialog = bool;
    },
    surees(bool) {
      this.WannaTalkdialog = bool;
    },
    // 获取问题组
    getHelperGroup() {
      hopeApi.getHelperGroup("", (res) => {
        this.data = res.data.result;

        this.id = this.data[0].children[0].uuid;
        hopeApi.getHelperText({ id: this.id }, (res) => {
          document.getElementById("test1").innerHTML = res.data.result;
        });
      });
    },
    //根据id获取帮助文本
    twosaveNave(node) {
      hopeApi.getHelperText({ id: node }, (res) => {
        document.getElementById("test1").innerHTML = res.data.result;
      });
    },
    // 我要留言
    keyword() {
        let isLogin = localStorage.getItem('Login_data')
        if(isLogin){
            this.dialogTableVisible = true;
        }
        else{
           Message.error('请先登录');
        }
    
    },
    // 点击咨询
    keyconsult() {
          let isLogin = localStorage.getItem('Login_data')
        if(isLogin){
             userApi.getUseridentityStates("",res=>{
        
          if(res.data.result==false){
                   Message.error('请先进行个人认证');
               
          }
          else{
        this.WannaTalkdialog = true;
        // 获取在线客服信息
         imApi.getServiceList("", (res) => {
        this.CompanyUserList = res.data.result;
       
      });
     
          }
        })
      
        }
        else{
           Message.error("请先登录");
         
        }
      // this.$router.push("/home/contact");
    },
    //   取消弹窗
    clear(bool) {
      this.dialogTableVisible = bool;
  
    },
    // 确定弹窗
    sure(bool) {
      this.dialogTableVisible = bool;
      this.KeyconsultTableVisible = bool;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.centere {
  width: 100%;
  // height: 600px;
}
.nav-wrap {
  width: 50%;
  margin: auto;
  margin-top: 100px;
  min-width: 1000px;
  .count {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding-bottom: 30px;
    .item {
      width: 180px;
      // height: 190px;
      background: #fdfdfd;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
     
      text-align: center;
      img {
        width: 40px;
        height: 40px;
        margin: 10px auto;
      }
      h2 {
        height: 27px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
      }
      p {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
        line-height: 20px;
      }
      .first {
        width: 140px;
        height: 40px;
        margin: 0 auto;
      }
      .two {
        // border: 1px solid red;
        margin-top: 40px;
        height: 44px;
        background: #e94f55;
        border-radius: 0px 0px 4px 4px;
        line-height: 44px;
        color: white;
      }
      .cupro{
        cursor: pointer;
      }
    }
  }
}
::v-deep .el-submenu__title {
  width: 200px !important;
}
.probleme {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  h2 {
    margin-bottom: 30px;
  }
  .problemcenter {
    display: flex;
    .left {
      //  border: 1px solid red;
      width: 200px;
    }
    .right {
      width: 80%;
      //  border: 1px solid red;
      padding: 8px 20px;
    }
  }
}
</style>
