<template>
    <div class="tan">
     <el-dialog :visible.sync="dialogTableVisible" :before-close="tanconfirm"  :close-on-click-modal="false">
                <div class="confirm">
                  <el-form class="confirmform" label-width="80px" ref="formdata" :model="formdata" :rules="rule">
                    <el-form-item label="标题" prop="title">
                      <el-input v-model='formdata.title'  placeholder="请输入您想反馈问题的标题"></el-input>
                    </el-form-item>
                    <el-form-item label="描述" prop="describe">
                      <el-input   type="textarea" class="desc" v-model='formdata.describe' placeholder="请输入您想反馈的详细问题"></el-input>
                    </el-form-item>
                    <div class="describe">补充照片(最多三张)</div>
                    <el-form-item>
                      
                      <el-upload
			 :action="baseApi+ '/center/file/upload'"
			  list-type="picture-card"
			  :on-success="handlePictureCardPreview"
        :before-upload="beforeAvatarUpload"
         :headers="httpheader"
			  :on-remove="handleRemove">
			  <i class="el-icon-plus"></i>
			</el-upload>
                   <el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
                    </el-form-item>
                 <el-button
                  type="danger"
                  class="fabu"
                  @click="reselve(formdata)"
                  >提交</el-button
                >
                   </el-form>
                </div>
              
              </el-dialog>

</div>
</template>
<script>
import { Message } from "element-ui";
import hopeApi from "../../api/hopeApi"
import imageZip from "../../utils/imageZip"
import basecfg from "../../utils/applocationCfg"
export default {
    data(){
        return{
           httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
             baseApi:basecfg.getBaseApi(),
            dialogVisible:false,
            
            dialogImageUrl:"",
         formdata:{
             title:"",
             describe:"",
              fileList:[],
         },
         rule:{
            title: [
               { required: true, message: '请输入问题标题' },
          ],
            describe: [
               { required: true, message: '请输入问题详情',  },
          ],
         }
        }
    },
      props: {
    dialogTableVisible: {
      type: Boolean,
      default: false,
    },
  },
    methods:{
  // 限制大小
         beforeAvatarUpload(file) {
        const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
      return imageZip.beforeUpload(file)
      
    },
     handlePictureCardPreview(file) {
    
      this.formdata.fileList.push(file.result)
   
    },
      handleRemove(file, fileList) {
      let fileListnew = []
      fileList.forEach(element => {
        let eleme =   element.response.result
          fileListnew.push(eleme)
      });
       this.formdata.fileList = fileListnew
     
    },
      tanconfirm() {
           this.formdata={}
           this.formdata.fileList = []
          this.$emit("clear", false);
       
    },
    // 发布
         reselve(){
       
          
            this.$refs.formdata.validate((valid) => {
              if(valid){
                 if(!this.formdata.fileList.length>0){
                Message.error("请选择补充的图片");
           }else{
              hopeApi.addFeedBack({title:this.formdata.title,describe:this.formdata.describe,image:this.formdata.fileList},(res)=>{
           
           if(res.data.code==200){
             Message.success("感谢您的反馈，我们会立即处理");
            // this.dialogTableVisible=false
           this.$emit("clear", false);
           this.formdata={}
           this.formdata.fileList = []
             
        }
        else{
           Message.error(res.data.message);
        }
    
     })
           }
                   
              }
     
   })
    },
    }
    
}
</script>
<style lang="scss" scope>
  .confirm {
  width: 100%;
  border-radius: 20px;
//   border: 1px solid red;
  
}

.tan ::v-deep .el-dialog {
  width: 600px;
  
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.tan .el-icon-plus{
  /* border: 1px solid red; */
  /* margin-top: -50px !important; */
  position: relative;
  top: 9px;
}
.confirmform {
  width: 90%;
//   border: 1px solid red;
  margin: 20px auto;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
}
::v-deep .el-textarea__inner {
  height: 300px !important;
 border: 1px solid red; 
  width: 100%;
}
.describe {
  margin-left: 40px;
}
.fabu {
  width: 300px;
  margin-left: 200px;
}
</style>