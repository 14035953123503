import baseApi from './baseApi.js';
const hopeApi = {
    // 获取问题组
    getHelperGroup(params,callback){
    baseApi.get('center/helper/getHelperGroup',params,callback)
 },
 //根据id获取帮助文本
 getHelperText(params,callback){
    baseApi.get('center/helper/getHelperText?id='+params.id,null,callback)
 },
//  提交问题反馈
addFeedBack(params,callback){
   baseApi.post('center/feedback/addFeedBack?title='+params.title +"&describe=" + params.describe + "&image=" + params.image ,null,callback)
},


}

//抛出api 
export default hopeApi;
